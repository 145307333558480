import React from 'react'

/**
 * callRepresentPhone
 * 대표번호로 전화걸기
 */
export function callRepresentPhone(e?: React.MouseEvent) {
  e?.preventDefault()
  window.open(`tel:${process.env.NEXT_PUBLIC_APP_TELNO!.replace('-', '')}`, '_self')
  return false
}

/**
 * sendMailTo
 * 마케팅제휴 이메일 보내기
 */
export function sendMailTo(e?: React.MouseEvent) {
  e?.preventDefault()
  window.open(`mailto:${process.env.NEXT_PUBLIC_APP_MAILTO}`, '_self')
  return false
}
