import React from 'react'
import { Scene } from 'react-scrollmagic-r18'
import Slider from 'react-slick'
import style from './Contents.module.scss'
import { ContentsBox, DownloadButton } from '@/features/common/components'
import { useCustomMediaQuery } from '@/features/common/hooks'
import useContentsHandler from '@/features/home/hooks/useContentsHandler'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function Contents() {
  const { UpToTablet, Mobile } = useCustomMediaQuery()
  const { settings, articlesModel } = useContentsHandler()

  return (
    <Scene duration={10} pin={{ pushFollowers: false }} triggerHook={1}>
      <section className={style.wrapper}>
        <UpToTablet>
          <div className={style.container}>
            <article className={style.container__notice}>
              <div className={style.container__notice__sticky}>
                <h2>
                  알면 도움되는
                  <br />더 운반의
                  <br />
                  새로운 소식
                </h2>
                <div>
                  <h3>
                    이렇게 편리한
                    <br />더 운반 앱 사용기.
                  </h3>
                  <p>
                    <span>이제 언제 어디서든 간편하게</span>
                    <span>더 운반 앱을 운송 현황을 확인할 수 있습니다.</span>
                  </p>
                  <DownloadButton style={{ width: 'fit-content' }} />
                </div>
              </div>
            </article>
            <div className={style.container__content}>
              {articlesModel.list.map((articleModel, index) => (
                <ContentsBox key={index} articleModel={articleModel} />
              ))}
            </div>
          </div>
        </UpToTablet>
        <Mobile>
          <div className={style.container}>
            <h2>
              알면 도움되는
              <br />더 운반의 새로운 소식
            </h2>
            <div className={style.container__content__mobile}>
              <Slider {...settings}>
                {articlesModel.list.map((articleModel, index) => (
                  <ContentsBox key={index} articleModel={articleModel} />
                ))}
              </Slider>
            </div>
          </div>
        </Mobile>
      </section>
    </Scene>
  )
}

export default Contents
