import style from './HomePanel.module.scss'
import { Contents, CustomerService, KeyVisual, Reviews } from '@/features/home/components'
import { QuickLink } from '@/features/common/components'
import LoadingBar from '@/features/common/components/LoadingBar'
import { useEffect, useState } from 'react'

function HomePanel() {
  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  if (!mounted) return <LoadingBar />

  return (
    <main className={style.container}>
      <KeyVisual />
      <Contents />
      <Reviews />
      <CustomerService />
      <QuickLink />
    </main>
  )
}

export default HomePanel
