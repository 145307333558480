import React from 'react'
import dynamic from 'next/dynamic'

const Header = dynamic(() => import('@/features/common/components/Header'), { ssr: false })
const Footer = dynamic(() => import('@/features/common/components/Footer'), { ssr: false })

interface ILayoutProps extends React.PropsWithChildren {}

function DefaultLayout(props: ILayoutProps) {
  return (
    <>
      <Header />
      <div className='root-container'>{props.children}</div>
      <Footer />
    </>
  )
}

export default DefaultLayout
