import { BaseService, ServiceBuilder } from 'ts-retrofit'
import RequestInterceptor from './requestInterceptor'
import ResponseInterceptor from './responseInterceptor'

class BaseApiService extends BaseService {
  private static instance: BaseApiService

  public static getInstance<T extends BaseApiService>(): T {
    if (!this.instance) {
      this.instance = new ServiceBuilder()
        .setEndpoint(process.env.NEXT_PUBLIC_API_URL || 'https://www.unban.ai')
        .setRequestInterceptors(RequestInterceptor)
        .setResponseInterceptors(ResponseInterceptor)
        .build(this)
    }

    return this.instance as T
  }
}

export default BaseApiService
