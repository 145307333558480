import PartnerDaehan from '@/assets/images/partner/partner_daehan.png'
import PartnerHcore from '@/assets/images/partner/partner_hcore.png'
import PartnerInavi from '@/assets/images/partner/partner_inavi.png'
import PartnerKiba from '@/assets/images/partner/partner_kiba.png'
import PartnerNice from '@/assets/images/partner/partner_nice.png'
import PartnerOlive from '@/assets/images/partner/partner_olive.png'
import { StaticImageData } from 'next/image'

type PartnerCompany = {
  image: StaticImageData
  name: string
  link?: [string, string] // ['데스크탑용 링크', '모바일용 링크']
}

export const PARTNER_COMPANIES: PartnerCompany[] = [
  {
    image: PartnerInavi,
    name: '아이나비 시스템즈',
  },
  {
    image: PartnerNice,
    name: 'NICE 디앤알',
  },
  {
    image: PartnerHcore,
    name: '현대제철 HCORE STORE',
    link: ['https://hcorestore.hyundai-steel.com/', 'https://hcorestore-m.hyundai-steel.com/'],
  },
  {
    image: PartnerKiba,
    name: '(사)한국산업단지 경영자연합회',
  },
  {
    image: PartnerDaehan,
    name: '대한상공회의소',
  },
  {
    image: PartnerOlive,
    name: 'CJ올리브네트웍스',
  },
]
