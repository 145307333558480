import style from './CustomerService.module.scss'
import Image from 'next/image'
import AppStore from '@/assets/images/app_store.png'
import PlayStore from '@/assets/images/play_store.png'
import { useDeepLink } from '@/features/common/hooks/useDeepLink'
import { callRepresentPhone } from '@/utils/contacts'

function CustomerService() {
  const { linkToPlayStore, linkToAppStore } = useDeepLink()

  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        <h2>
          더 운반이 더 궁금할때,
          <br />
          바로 연결하기
        </h2>
        <div className={style.container__contents}>
          <div className={style.container__contents__cs}>
            <p>
              <span>고객센터 (평일/주말 24시간)</span>
            </p>
            <p>
              <a href='#' onClick={callRepresentPhone}>
                {process.env.NEXT_PUBLIC_APP_TELNO}
              </a>
            </p>
          </div>
          <div className={style.container__contents__app}>
            <p>
              <span>더 운반 앱 다운로드</span>
            </p>
            <p>
              <button onClick={linkToPlayStore}>
                <Image src={PlayStore} alt='더 운반 플레이 스토어 이미지' />
              </button>
              <button onClick={linkToAppStore}>
                <Image src={AppStore} alt='더 운반 앱 스토어 이미지' />
              </button>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CustomerService
