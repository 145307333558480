import { useEffect, useState } from 'react'
import style from './RightArrow.module.scss'
import ArrowRightIcon from '@/assets/icons/icon-arrow-right.svg'

function RightArrow(props) {
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const { className, onClick } = props

  useEffect(() => {
    const disabled = className.includes('disabled')

    setIsDisabled(disabled)
  }, [className])

  if (isDisabled) return null

  return (
    <div onClick={onClick} className={style.container}>
      <ArrowRightIcon />
    </div>
  )
}

export default RightArrow
