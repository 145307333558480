import { Body, POST, Response } from 'ts-retrofit'
import BaseApiService from './baseApiService'
import { GetArticlesDto } from '@models/getArticlesDto'
import { GetArticleDto } from '@models/getArticleDto'

class ArticleApi extends BaseApiService {
  @POST('/api/articles')
  async getArticles(@Body request: GetArticlesDto.Request): Promise<Response<GetArticlesDto.Response>> {
    return {} as Response<GetArticlesDto.Response>
  }

  @POST('/api/article')
  async getArticle(@Body request: GetArticleDto.Request): Promise<Response<GetArticleDto.Response>> {
    return {} as Response<GetArticleDto.Response>
  }
}

export const articleApi = ArticleApi.getInstance<ArticleApi>()
