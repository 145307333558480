import React, { useRef, useState, useEffect } from 'react'
import style from './KeyVisual.module.scss'
import { SERVICE_PATH } from '@/constants/routes'
import { useCustomRouter, useFadeInAnimation } from '@/features/common/hooks'
import { sendCustomEvent } from '@/utils/GTM'

function KeyVisual() {
  const { handlePush } = useCustomRouter()
  const { handleExternalLink } = useCustomRouter()
  const { animationFadeInUp } = useFadeInAnimation(0)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [isReady, setIsReady] = useState(false)

  const handleChangeRoute = (url: string) => {
    sendCustomEvent({
      event: 'click',
      category: '내부 링크 이동 클릭',
      label: 'KeyVisual 서비스 소개',
      value: url,
    })
    handlePush(url).catch()
  }

  const handleExternalRoute = (url: string, label: string) => {
    sendCustomEvent({
      event: 'click',
      category: '외부 링크 이동 클릭',
      label: `KeyVisual 영상(${label}) - ${url}`,
      value: url,
    })
    handleExternalLink(url)
  }

  useEffect(() => {
    if (isReady) {
      if (iframeRef.current) {
        iframeRef.current.src = 'https://www.youtube.com/embed/l7-OgFG-lng'
      }
    } else {
      setIsReady(true)
    }
  }, [isReady])

  return (
    <section className={style.container}>
      <div {...animationFadeInUp[0]} className={style.container__left}>
        <article onClick={() => handleChangeRoute(`${SERVICE_PATH}`)}>
          <div>
            <span>서비스</span>
            <h2>
              더 앞선 운반의 기준,
              <br />더 운반
            </h2>
          </div>
          <p>
            {isReady ? (
              <iframe
                ref={iframeRef}
                title='Youtube'
                width='100%'
                height='100%'
                allowFullScreen={true}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              />
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background:
                    'transparent url("https://i.ytimg.com/vi_webp/l7-OgFG-lng/maxresdefault.webp") 50% 50% no-repeat',
                  backgroundSize: 'cover',
                }}
              />
            )}
          </p>
        </article>
      </div>
      <div className={style.container__right}>
        <article
          {...animationFadeInUp[1]}
          className={style.container__right__top}
          onClick={() => handleExternalRoute('https://youtu.be/_lybz_RV1YM', '비용절감')}
        >
          <div>
            <span>인사이트</span>
            <h2>화물 운임, 더 줄일 수 없을까?</h2>
          </div>
          <p>
            <span>
              거래 단계 축소로 중간 마진 제거, 플랫폼 서비스 전면 무료 등 운임을 절약할 수 있는 더 운반의 장점을
              알려드릴게요.
            </span>
          </p>
        </article>
        <div className={style.container__right__bottom}>
          <article
            {...animationFadeInUp[2]}
            onClick={() => handleExternalRoute('https://youtu.be/wfCzCZdTC4E', '신뢰성')}
          >
            <div>
              <span>인사이트</span>
              <h2>화물 운송, 믿고 맡겨도 괜찮을까?</h2>
            </div>
            <p>
              <span>
                최적 차량 매칭 배차 성공률 90%, 빈틈 없는 24시간 전담 운영팀 등 믿을 수 있는 더 운반에 대해
                알려드릴게요.
              </span>
            </p>
          </article>
          <article
            {...animationFadeInUp[3]}
            onClick={() => handleExternalRoute('https://youtu.be/AbAPRaqiw5Y', '편의성')}
          >
            <div>
              <span>인사이트</span>
              <h2>불편한 운송 업무 바꿀 수는 없는 걸까?</h2>
            </div>
            <p>
              <span>
                실시간 화물 위치 확인 및 간편한 운송 관리 및 자동 정산 등, 더 운반의 편리함에 대해 알려드릴게요.
              </span>
            </p>
          </article>
        </div>
      </div>
    </section>
  )
}

export default KeyVisual
