import { useEffect, useState } from 'react'
import { articleApi } from '@/api/unban/articleApi'
import { useQuery } from '@tanstack/react-query'
import { useCustomRouter } from '@/features/common/hooks'
import { sendCustomEvent } from '@/utils/GTM'
import CategoryType from '@models/categoryType'
import { ArticleModelMapper } from '@/features/common/mappers/ArticleModelMapper'
import ArticlesModel from '@/features/common/models/articlesModel'

const useInterviewHandler = () => {
  const { handleExternalLink } = useCustomRouter()
  const [articlesModel, setArticlesModel] = useState<ArticlesModel>({ list: [], totalCount: 0 })
  const settings = {
    slidesPerRow: 1,
    infinite: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    arrows: false,
  }

  const {
    refetch: fetchContents,
    isSuccess: isSuccessFetchContents,
    data: contentsResult,
  } = useQuery(['fetchInterview'], async () => {
    const { data: response } = await articleApi.getArticles({ category: CategoryType.INTERVIEW })
    return response.result
  })

  const handleExternalRoute = (url: string) => {
    sendCustomEvent({
      event: 'click',
      category: '앱 다운로드 링크 클릭',
      label: '화주 Android 앱 다운로드',
      value: url,
    })

    handleExternalLink(url)
  }

  useEffect(() => {
    fetchContents()
  }, [])

  useEffect(() => {
    if (!isSuccessFetchContents || !contentsResult) return

    const model = ArticleModelMapper.mapsToPresentation(contentsResult)
    setArticlesModel(model)
  }, [isSuccessFetchContents, contentsResult])

  return {
    settings,
    articlesModel,
    handleExternalRoute,
  }
}

export default useInterviewHandler
